<template>
  <div
    v-loading="downloadLoading"
    element-loading-text="导出中"
    element-loading-spinner="el-icon-loading"
  >
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">门禁管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/business/workAttendance">考勤统计</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      :rules="ruleValidate"
      inline
      class="demo-form-inline"
    >
      <div>
        <el-row>
          <el-form-item label="单位" prop="companyId">
            <!-- <el-select
              v-model="queryModel.companyId"
              size="mini"
              filterable
              placeholder="请选择"
              style="width:220px"
            >
              <el-option
                v-for="company in companyResult"
                :key="company.id"
                :label="company.name"
                :value="company.id"
              ></el-option>
            </el-select>-->
            <el-select-tree
              :props="props"
              :options="companyResult"
              v-model="queryModel.companyId"
              height="200"
            ></el-select-tree>
            &nbsp;
            <el-checkbox v-model="queryModel.subordinate">是否包含下级单位</el-checkbox>
          </el-form-item>
          <el-form-item label="统计区间" prop="timeRanges">
            <el-date-picker
              v-model="queryModel.timeRanges"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :default-time="timeRangesDefaultTime"
              size="mini"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input type="text" size="mini" v-model="queryModel.name" style="width:100px;"></el-input>
          </el-form-item>
          <el-form-item label="编号" prop="name">
            <el-input type="text" size="mini" v-model="queryModel.personId" style="width:100px;"></el-input>
          </el-form-item>
          <!-- <el-form-item v-if="position1Show" :label="position1" prop="position1">
            <el-input type="text" size="mini" v-model="queryModel.position1"></el-input>
          </el-form-item>
          <el-form-item v-if="position2Show" :label="position2" prop="position2">
            <el-input type="text" size="mini" v-model="queryModel.position2"></el-input>
          </el-form-item>
          <el-form-item v-if="position3Show" :label="position3" prop="position3">
            <el-input type="text" size="mini" v-model="queryModel.position3"></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button
              type="primary"
              size="mini"
              style="margin-left: 8px"
              @click="handleQuery('queryForm')"
            >查询</el-button>&nbsp;
            <el-button
              type="info"
              size="mini"
              style="margin-left: 8px"
              @click="handleReset('queryForm')"
            >重置</el-button>
          </el-form-item>
        </el-row>
      </div>
      <!-- <div>
        <el-form-item v-if="position4Show" :label="position4" prop="position4">
          <el-input type="text" size="mini" v-model="queryModel.position4"></el-input>
        </el-form-item>
        <el-form-item v-if="position5Show" :label="position5" prop="position5">
          <el-input type="text" size="mini" v-model="queryModel.position5"></el-input>
        </el-form-item>
      </div> -->
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="exportXls"
      >导出数据</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-edit"
        :loading="loading"
        @click="handleBatchUpdate"
        v-show="batchUpdateVisible"
      >重新生成考勤数据</el-button>
    </el-row>
    <el-table 
    ref="formTable" 
    stripe 
    :data="tableData" 
    :height="tableHeight" 
    style="width: 100%" 
    v-loading="loading"
    :element-loading-text="loadingText">
      <el-table-column label="序号" fixed="left" type="index" :index="indexMethod"></el-table-column>
      <el-table-column label="姓名" fixed="left" prop="name"></el-table-column>
      <el-table-column label="编号" prop="personId"></el-table-column>
      <el-table-column label="身份证号" prop="idCard" width="200"></el-table-column>
      <el-table-column label="手机" prop="phone" width="200"></el-table-column>
      <el-table-column label="公司" prop="companyName" width="200"></el-table-column>
      <el-table-column label="上级部门" prop="parentDepartmentName" width="200"></el-table-column>
      <el-table-column label="部门" prop="departmentName" width="200"></el-table-column>
      <!-- <el-table-column prop="position1" :label="position1" v-if="position1Show"></el-table-column>
      <el-table-column prop="position2" :label="position2" v-if="position2Show"></el-table-column>
      <el-table-column prop="position3" :label="position3" v-if="position3Show"></el-table-column>
      <el-table-column prop="position4" :label="position4" v-if="position4Show"></el-table-column>
      <el-table-column prop="position5" :label="position5" v-if="position5Show"></el-table-column> -->
      <el-table-column label="出勤天数" prop="workDays"></el-table-column>
      <el-table-column label="请假天数" prop="restDays"></el-table-column>
      <el-table-column label="迟到次数" prop="lateNum"></el-table-column>
      <el-table-column label="早退次数" prop="leaveNum"></el-table-column>
      <el-table-column label="上班缺卡次数" prop="missCardOnWorkCount"></el-table-column>
      <el-table-column label="下班缺卡次数" prop="missCardOffWorkCount"></el-table-column>
      <el-table-column label="旷工天数" prop="missCardAllDayCount"></el-table-column>
      <template v-for="col in dayColumns">
        <el-table-column :label="col.label" :prop="col.name" :key="col.name" width="200px">
          <template slot-scope="{row}">
            <div style="display:flex;flex-direction:column;">
              <template v-for="(item,index) in row.workAttendanceMap[col.name]">
                <div v-html="showItem(item)" :key="index"></div>
              </template>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script>
import Constant from "@/constant";
import workAttendanceApi from "@/api/business/workAttendance";
import companyPositionApi from "@/api/base/companyPosition";
import companyInfoApi from "@/api/base/companyInfo";
import permissionApi from "@/api/sys/permission";
import SelectTree from "@/components/SelectTree";

import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BusinessWorkAttendanceList",
  data() {
    var self = this;

    return {
      ruleValidate: {
        companyId: [{ required: true, message: "不能为空", trigger: "blur" }],
        timeRanges: [
          { required: true, message: "请选择事件范围", trigger: "blur" }
        ]
      },
      queryModel: {
        companyId: "",
        timeRanges: "",
        subordinate: false,
        name: "",
        personId: null,
        jobNumber: "",
        position1: "",
        position2: "",
        position3: "",
        position4: "",
        position5: ""
      },
      loading: false,
      loadingText: "加载中",
      tableData: [],
      pageIndex: 1,
      pageSize: 20,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [20, 30, 50],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      downloadLoading: false,
      tableHeight: 400,
      timeRangesDefaultTime: [],
      companyResult: [],
      editorOption: {
        modules: {
          toolbar: "title" // 设置文本编辑器的头部是否展示
        },
        placeholder: "", // 文本框为空时 , 占位文本
        theme: "snow" // 或者为 `bubble`
      },
      downloadUrl: "",
      dayColumns: [],
      tmplKey: "",
      position1: "",
      position2: "",
      position3: "",
      position4: "",
      position5: "",
      position1Show: false,
      position2Show: false,
      position3Show: false,
      position4Show: false,
      position5Show: false,
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
      batchUpdateVisible: false
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });

    companyPositionApi.detailForCompany().then(function(response) {
      var jsonData = response.data.data;
      if (jsonData.position1Name != null && jsonData.position1Name != "") {
        self.position1 = jsonData.position1Name;
        self.position1Show = true;
      }
      if (jsonData.position2Name != null && jsonData.position2Name != "") {
        self.position2 = jsonData.position2Name;
        self.position2Show = true;
      }
      if (jsonData.position3Name != null && jsonData.position3Name != "") {
        self.position3 = jsonData.position3Name;
        self.position3Show = true;
      }
      if (jsonData.position4Name != null && jsonData.position4Name != "") {
        self.position4 = jsonData.position4Name;
        self.position4Show = true;
      }
      if (jsonData.position5Name != null && jsonData.position5Name != "") {
        self.position5 = jsonData.position5Name;
        self.position5Show = true;
      }
    });

    permissionApi.hasPermission("post","/business/workAttendance/batchUpdate")
    .then(response=>{
      this.batchUpdateVisible = response.data.result;
    });

    this.getCurrentMonthFirst();
    this.loadTree();
  },
  methods: {
    getSelectedValue(value) {
      this.queryModel.companyId = value;
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    changePage(pageIndex) {
      var self = this;
      self.pageIndex = pageIndex;

      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      if (self.queryModel.companyId == null) {
        self.queryModel.companyId = "";
      }
      
      formData.append("companyId", self.queryModel.companyId);
      formData.append("subordinate", self.queryModel.subordinate);
      formData.append("name", self.queryModel.name);

      if(self.queryModel.personId!=null){
        formData.append("personId", self.queryModel.personId);
      }

      var startDate = "";
      var endDate = "";
      var timeRanges = self.queryModel.timeRanges + "";

      if (timeRanges != "" && timeRanges != null) {
        timeRanges = timeRanges.split(",");
        startDate = timeRanges[0];
        endDate = timeRanges[1];
      }

      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("position1", self.queryModel.position1);
      formData.append("position2", self.queryModel.position2);
      formData.append("position3", self.queryModel.position3);
      formData.append("position4", self.queryModel.position4);
      formData.append("position5", self.queryModel.position5);

      self.loading = true;
      self.loadingText = "加载中";

      workAttendanceApi.statList(formData).then(function(response) {
        var jsonData = response.data;

        self.loading = false;

        if (jsonData.result) {
          self.tableData = jsonData.data.data;
          self.totalPages = jsonData.data.totalPage;
          self.totalElements = jsonData.data.totalElements;

          self.dayColumns = jsonData.data.dayColumns;
          self.tmplKey = jsonData.data.tmplKey;

          //45为分页栏的高度
          //页面高度-列表上面的高度-分页栏高度
          self.tableHeight =
            window.innerHeight - self.$refs.formTable.$el.offsetTop - 100;
        } else {
          self.$message({
            type: "warning",
            message: jsonData.message
          });
        }
      });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.changePage(1);
    },
    showItem(item) {
      var content = [];

      var arr = item.recordTime.split(" ");

      if (arr.length > 1) {
        content.push(arr[1]);
      } else {
        content.push(arr[0]);
      }

      content.push(" ");

      if (item.sources == "1") {
        content.push("内勤");
      } else {
        content.push("外勤");
      }

      if (item.classifier == 1) {
        content.push("上班");
      } else {
        content.push("下班");
      }

      var fontColor = "";

      if (item.result == "0") {
        content.push("缺卡");
        fontColor = "red";
      } else if (item.result == "1") {
        content.push("打卡");
        fontColor = "green";
      } else if (item.result == "2") {
        content.push("迟到");
      } else if (item.result == "3") {
        content.push("早退");
      }else if(item.result == "4"){
        content.push("请假");
        fontColor = "#11A8CD";
      }

      if(item.workOutRemark!=null){
        content.push("(" + item.workOutRemark + ")");
      }

      return `<font color='${fontColor}'>` + content.join("") + "</font>";
    },
    handleQuery() {
      var self = this;

      this.$refs["queryForm"].validate(valid => {
        if (valid) {
          self.changePage(1);
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    exportXls() {
      var self = this;

      //导出
      this.$refs["queryForm"].validate(valid => {
        if (valid) {
          self.downloadLoading = true;

          var formData = new FormData();

          if (self.queryModel.companyId == null) {
            self.queryModel.companyId = "";
          }
          
          formData.append("companyId", self.queryModel.companyId);
          formData.append("subordinate", self.queryModel.subordinate);
          formData.append("name", self.queryModel.name);

          if(self.queryModel.personId!=null){
            formData.append("personId", self.queryModel.personId);
          }

          var startDate = "";
          var endDate = "";
          var timeRanges = self.queryModel.timeRanges + "";

          if (timeRanges != "" && timeRanges != null) {
            timeRanges = timeRanges.split(",");
            startDate = timeRanges[0];
            endDate = timeRanges[1];
          }

          formData.append("startDate", startDate);
          formData.append("endDate", endDate);
          formData.append("position1", self.queryModel.position1);
          formData.append("position2", self.queryModel.position2);
          formData.append("position3", self.queryModel.position3);
          formData.append("position4", self.queryModel.position4);
          formData.append("position5", self.queryModel.position5);

          workAttendanceApi.exportXls(formData).then(function(response) {
            var jsonData = response.data;

            self.downloadLoading = false;

            if (jsonData.result) {
              if(document.location.href.startsWith("https://")){
                  jsonData.data = jsonData.data.replace("http://","https://");
              }

              self.$message({
                type: "success",
                message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                dangerouslyUseHTMLString: true,
                duration: 30000
              });
            } else {
              self.$message({
                type: "warning",
                message: jsonData.message
              });
            }
          });
        }
      });
    },
    //初始化日期
    getCurrentMonthFirst() {
      var self = this;
      var date = new Date();
      date.setDate(1);
      var month = parseInt(date.getMonth() + 1);
      var startTime = date.getFullYear() + "-" + month + "-" + date.getDate();

      date.setMonth(date.getMonth()+1);
      var lastDate = new Date(date.getTime()-1000*60*60*24);
      
      var endTime = lastDate.getFullYear() + "-" + month + "-" + lastDate.getDate();
      
      self.queryModel.timeRanges = [startTime, endTime];
    },
    handleBatchUpdate() {
      var self = this;

      self.$confirm("是否确认更新考勤数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
      }).then(() => {
        var formData = new FormData();

        formData.append("companyId", this.queryModel.companyId);

        if(this.queryModel.personId!=null){
          formData.append("personId", this.queryModel.personId);
        }

        if(this.queryModel.name!=null){
          formData.append("personName", this.queryModel.name);
        }

        formData.append("startDate", this.queryModel.timeRanges[0]);
        formData.append("endDate", this.queryModel.timeRanges[1]);

        self.loading = true;
        self.loadingText = "考勤数据生成中...";

        workAttendanceApi.batchUpdate(formData).then(function(response) {
          var jsonData = response.data;

          self.loading = false;

          if (jsonData.result) {
            self.$message({
              type: "success",
              message: jsonData.message + ""
            });

            self.handleQuery();
          } else {
            self.$message({
              type: "warning",
              message: jsonData.message + ""
            });
          }
        });
      });
    }
  },
  mounted: function() {},

  components: {
    "el-select-tree": SelectTree
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>